import { Component, EventEmitter, Input, Output } from '@angular/core';

import { I18nService, TranslatePipe } from '@recruitee/i18n';
import type { ReferralsPortalApplication } from '@recruitee/referrals-types';
import { ReferralContext, ReferralsPortalApplicationStatus } from '@recruitee/referrals-types';
import {
  IconName,
  TimeAgoPipe,
  RtTooltip,
  IconComponent,
  IconColorDirective,
  HeadingComponent,
  AvatarComponent,
  BoxComponent,
} from '@recruitee/user-interface';

@Component({
  selector: 'rtr-referrals-card',
  templateUrl: './referrals-card.component.html',
  styleUrls: ['./referrals-card.component.less'],
  standalone: true,
  imports: [
    BoxComponent,
    AvatarComponent,
    HeadingComponent,
    IconColorDirective,
    IconComponent,
    RtTooltip,
    TimeAgoPipe,
    TranslatePipe,
  ],
})
export class ReferralsCardComponent {
  public readonly ReferralStatus: typeof ReferralsPortalApplicationStatus =
    ReferralsPortalApplicationStatus;
  public readonly ReferralContext: typeof ReferralContext = ReferralContext;

  @Input() public application: ReferralsPortalApplication;
  @Input() public showStatus: boolean;
  @Input() public type: ReferralContext;
  @Input() public rewardsVisible: boolean;
  @Input() public statusTranslationsMap: Record<string, { status: string; icon: IconName }>;
  @Output() public referralSelect: EventEmitter<ReferralsPortalApplication> = new EventEmitter();

  public offerTooltipTranslations: Record<string, string> = {
    [ReferralsPortalApplicationStatus.InReview]: this.i18n.translate(
      'rtr.job_details.status_tooltip.in_progress',
    ),
    [ReferralsPortalApplicationStatus.ProcessComplete]: this.i18n.translate(
      'rtr.job_details.status_tooltip.completed',
    ),
    [ReferralsPortalApplicationStatus.Claimed]: this.i18n.translate(
      'rtr.job_details.status_tooltip.success',
    ),
  };

  constructor(private i18n: I18nService) {}

  public selectCandidate(application: ReferralsPortalApplication): void {
    if (!this.rewardsVisible || !this.application.referralReward) return;
    this.referralSelect.emit(application);
  }
}
